<template>
  <section class="already-submitted">
    {{ $t("alreadySubmitted") }}
  </section>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ViewAlreadySubmitted extends Vue {}
</script>
<style lang="scss" scoped>
@use "src/styles/main";

.already-submitted {
  @include main.page();
}
</style>
